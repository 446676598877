table {
  width: 100%;
}

@mixin disabled-row {
  background: $color-background-light;
  &:hover {
    background: $color-background-light;
  }
}

// Basic Table -- One table to inherit them all

@mixin basic-table {
  $outer-border-color: darken($color-background-medium, 20%);

  border-bottom: 1px solid $outer-border-color;
  font-weight: normal;
  text-align: left;

  tr {
    border-bottom: 1px solid $color-background-medium;

    &:last-child {
      border-color: $outer-border-color;
    }

    &.header {
      cursor: pointer;
    }
  }

  th {
    font-weight: bold;
    padding: ($line-height * 0.5) 5px;
    position: relative;
    text-transform: capitalize;

    &.asc::after {
      @include iconfont;
      content: $ds-icon-menu-arrow-up;
    }

    &.desc::after {
      @include iconfont;
      content: $ds-icon-menu-arrow-down;
    }
  }

  tbody {
    td {
      padding: ($half-line) 5px;
    }

    tr {
      &:nth-child(2n) {
        background: $color-background-ghost;
      }
    }

    tr.locked {
      background: lighten($color-alert, 62%);
    }

    tr.expired {
      background: lighten($color-alert, 62%);
      text-decoration: line-through;
    }
  }
}

.basic-table {
  @include basic-table;
}

summary.user-table-header {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 1.25em;
}

// Row Removal / Deletion

.remove-icon {
  background: $color-background-paper;
  border-radius: 10px;
  display: none;
  position: absolute;
  top: -8px;
  left: -8px;
  height: 20px;
  width: 20px;
  overflow: hidden;
  text-indent: -9999px;
  z-index: 100;

  &:before {
    @include iconfont;
    content: $ds-icon-remove-fill;
    color: $color-error;
    display: block;
    font-size: 18px;
    left: 2px;
    position: absolute;
    text-indent: 0;
    top: 2px;
  }

  &:hover {
    background: $color-background-ghost;
  }
}

tbody tr:hover {
  .remove-icon {
    display: block;
  }
}

// Accounts

table {
  .organization {
    img {
      background: $color-background-ghost;
      border: 3px solid $color-background-paper;
      height: 40px;
      width: 40px;
      float: left;
      margin-right: $half-gutter;

      box-shadow: 0 2px 2px $color-shadow-subdued;
    }

    h5 {
      font-size: 1.25em;
      margin: 0.5em 0;
    }

    p {
      color: $color-text-subdued;
      margin: 0;
    }
  }

  td.user {
    width: 70%;
  }

  td.admin {
    width: 30%;

    label {
      width: auto;
    }
  }
}
