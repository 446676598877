form {
  // Differentiate an immediately nested legend from that inside of a fieldset
  > legend {
    color: inherit;
    font-size: 1.333em;
    font-weight: bold;
    line-height: 1.5em;
    text-transform: uppercase;
  }

  // Override the default side-by-side label / input format
  &.stacked {
    label {
      display: block;
      margin-bottom: $half-line;
    }
  }
}

/*
  Ensure the correct input font-family
*/
input,
textarea,
select,
button {
  font-family: $sans-serif-font;
}

optgroup {
  font-style: normal;
  text-indent: 4px;
}

/*
  Fieldsets are widely used to separate form sections, we are adding
  basic padding and a divider for each
*/
fieldset {
  border-bottom: 1px solid darken($color-background-paper, 20%);
  clear: both;
  float: none;
  margin: 0 0 $line-height;
  padding-bottom: $half-line;

  &.inline-wrapper {
    border: none;
    margin-bottom: $half-line;
    padding-bottom: 0;
    overflow: hidden;
  }

  &.last {
    border-bottom: none;
    padding-bottom: 0;
  }

  > legend {
    color: $color-text;
    font-size: 1em;
    font-weight: bold;
    line-height: $line-height;
    padding-bottom: $line-height;
  }

  > h6 {
    font-weight: bold;
  }
}

// Labels ---------------------------------------------------------------------

label {
  color: $color-text;
  display: inline-block;
  vertical-align: middle;

  &.required {
    color: darken($color-text-dark, 10%);
    font-weight: bold;
  }
}

textarea {
  resize: vertical;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
textarea {
  background-color: $color-background-paper;
  border-radius: $radius-primary;
  border: 1px solid darken($color-background-paper, 20%);
  box-sizing: border-box;
  color: $color-text;
  display: inline-block;
  font-size: 14px;
  padding: 5px;
  transition: border 0.2s;
  width: 40%;

  &:focus {
    border: 1px solid desaturate($color-highlight, 10%);
    outline: none;
    box-shadow: 0 0 5px
      adjust-color($color-highlight, $saturation: -10%, $lightness: 20%);
  }

  &:disabled {
    background: lighten($color-background-light, 2%);
  }

  &.zip {
    width: 78px;
  }

  $color-text-placeholder: lighten($color-text-light, 30%);

  &.placeholder {
    color: $color-text-placeholder;
  }
}

textarea {
  line-height: 1.5em;
  width: 77%;
}

input[type='number'] {
  width: 4.5em;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline-block;
  font-size: 15px;
  line-height: 1em;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: middle;
}

input[type='submit'],
button[type='submit'] {
  background: $color-dscout;
  border: 1px solid $color-dscout;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  padding: 0.5em 1em;
  text-shadow: none;
}

// Images ---------------------------------------------------------------------
// Wrap an image and the form fields for uploading and caching it and hang them
// to the side of the label.

.image-fields {
  label {
    vertical-align: top;
  }

  .image-wrapper {
    display: inline-block;
    padding-left: $half-gutter;
    width: 50%;

    > img {
      border: 1px solid $color-background-medium;
      box-shadow: 0 0 5px $color-shadow-subdued;
      display: block;
      padding: 5px;
      margin: 0 0 $line-height 0;
    }
  }
}

// Utility --------------------------------------------------------------------

.hint {
  color: $color-text-subdued;
  margin-left: $half-gutter;

  em,
  abbr {
    font-size: 0.833em;
    font-style: normal;
    font-weight: bold;
    vertical-align: baseline;
    text-transform: uppercase;
  }
}

.disabled {
  color: $color-text-subdued;
}

.ender {
  float: right;
  text-align: right;
}

// Cancel ---------------------------------------------------------------------

.submit-cancel,
.cancel {
  color: $color-text-subdued;
  cursor: pointer;
  margin-right: $half-gutter;
  text-transform: capitalize;

  &:hover {
    text-decoration: underline;
  }
}

// Simple Form ----------------------------------------------------------------

.input {
  margin: $line-height 0;
  position: relative;

  &.text {
    label {
      vertical-align: top;
    }
  }
  &.last {
    input {
      width: 100%;
    }
  }
  &.submit {
    margin-bottom: 0;
  }
}

.radio {
  input[type='radio'] {
    margin-right: $grid-gutter * 0.25;
  }

  // Spacing for individual radio labels
  span label {
    display: inline;
    margin-right: $grid-gutter;
  }
}

.controls {
  padding: 10px;
  text-align: right;
}

.form-wrapper {
  border-bottom: 1px solid $color-ui-gray-2;
  overflow: hidden;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

// Errors ---------------------------------------------------------------------

@mixin error-field {
  border: 1px solid desaturate($color-error, 10%);
  outline: none;
  box-shadow: 0 0 5px
    adjust-color($color-error, $saturation: -10%, $lightness: 10%);
}

.field-with-errors {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'],
  textarea {
    @include error-field;
  }

  .error {
    color: $color-error;
    display: block;
    padding-top: $line-height * 0.5;
  }

  .hint {
    display: none;
  }
}

.form-error {
  background: none repeat scroll 0 0 #fbe3e4;
  border: 1px solid #fbc2c4;
  border-radius: $radius-primary;
  color: #8a1f11;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 0 1em;
  padding: 10px;
}

// Inline error display

.inline-error-message {
  background: $color-background-paper;
  clear: both;
  color: $color-danger;
  display: block;
  margin-top: 5px;

  span {
    line-height: 1.4;
    position: relative;
    z-index: 2;
  }
}

.small-form {
  width: 40%;
  margin: 0 auto;

  p.informative {
    line-height: 120%;
    font-size: 18px;
  }

  input[type='password'] {
    height: 42px;
    width: 50%;
  }

  .primary {
    width: 40%;
    margin-left: 12px;
  }
}
