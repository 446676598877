@import '../alpha/variables';

.switch-toggle {
  background: $color-background-paper;
  border: 1px solid darken($color-background-medium, 5%);
  border-radius: $radius-secondary;
  color: $color-text-subdued;
  cursor: pointer;
  display: inline-block;
  height: $line-height;
  line-height: $line-height;
  position: relative;
  right: 0;
  text-indent: 30px;
  top: $line-height;
  width: 60px;

  &:before {
    background: $color-background-paper;
    border: 1px solid $color-background-medium;
    border-radius: $radius-secondary;
    content: "";
    height: 20px;
    left: -1px;
    position: absolute;
    top: -1px;
    width: 20px;
    transition: left 0.1s ease-in-out;
  }

  &.on {
    background: $color-highlight;
    color: $color-text-inverted;
    text-indent: 10px;

    &:before { left: 40px; }
  }
}
