#scout_sessions,
#sessions,
#passwords,
#unlocks,
#conversions {
  -webkit-font-smoothing: antialiased;
  background: $color-background-soft;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  @media (min-width: 560px) {
    padding-top: 100px;
  }

  a {
    border-bottom: 1px solid rgba($color-dscout, 0.5);
    color: $color-dscout;
    text-decoration: none;
    transition: 250ms;

    &:hover {
      border-bottom: 1px solid $color-dscout;
      color: $color-dscout;
    }

    &.svg-link {
      border-bottom: none;
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 1em;
    text-align: center;
  }

  h1.form-heading {
    color: $color-text-dark;
    font-size: $font-size-large;
    font-weight: $font-weight-normal;
    line-height: $line-height-normal;
    padding: 0 0 0.4em;
    margin: 1em 0;
  }

  h2 {
    margin: $line-height 0;

    &.form-subheading {
      font-size: $font-size-normal;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
      text-shadow: 1px 1px 1px $color-text-inverted;
    }
  }

  fieldset {
    text-align: left;
  }

  .simple_form,
  .simple-form {
    box-sizing: border-box;
    color: $color-charcoal;
    font-size: 0.9rem;
    margin: 0 auto;
    max-width: 400px;
    overflow: visible;

    @media (max-width: 560px) {
      margin: 0.5em;
      max-width: 100%;
    }

    label.required {
      color: $color-charcoal;
      font-weight: normal;
    }

    fieldset {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .button,
    input[type='submit'],
    button[type='submit'] {
      background-color: $color-dscout;
      border: none;
      border-radius: 4px;
      box-shadow: 0 5px 10px rgba(#404040, 0.3);
      box-sizing: border-box;
      color: #fff;
      display: block;
      font-family: inherit;
      font-size: 0.9rem;
      outline-offset: 5px;
      padding: 1em;
      transition:
        background-color 250ms,
        box-shadow 250ms;
      width: 100%;

      &:hover {
        background-color: mix(black, $color-dscout, 20%);
        box-shadow: 0 10px 20px rgba(#404040, 0.3);
      }

      &:active {
        background-color: mix(black, $color-dscout, 40%);
        box-shadow: none;
      }
    }

    h2 {
      font-family: inherit;

      + p {
        color: $color-ui-gray-3;
      }
    }
  }

  .signin-form {
    background-color: $color-white;
    border-radius: $radius-secondary;
    box-shadow: 0 10px 30px rgba($color-charcoal, 0.1);
    padding: 2em;

    .button-spacer {
      color: $color-text-medium;
      padding-bottom: 1em;
      padding-top: 1em;
    }

    .google-sso--button {
      align-items: center;
      background-color: $color-white;
      display: flex;
      justify-content: center;
      margin-bottom: 2em;

      &:hover {
        background-color: $color--faint;
      }

      &:active {
        background-color: $color--faint-s;
      }
    }

    .google-sso--link {
      border-bottom: none;
      color: $color-text;
      padding-left: 1em;
    }
  }

  .input {
    margin: 0;
  }

  .signin-form fieldset {
    label {
      display: inline-block;
      margin-bottom: 0.5em;
    }

    input[type='email'],
    input[type='password'],
    input[type='text'] {
      appearance: none;
      background-color: $color--faint;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 1em;
      margin: 0.5em 0 2em 0;
      padding: 1em 0.5em;
      width: 100%;

      &:focus {
        background-color: #fff;
        border-color: rgba($color-dscout, 0.8);
        box-shadow: none;
      }

      &.error {
        background-color: rgba($color-alert, 0.05);
        border: 1px solid rgba($color-alert, 0.3);
      }

      &::placeholder {
        color: #4d4d4d;
      }
    }
  }

  .support-links {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
  }
}

.external-notice-wrapper {
  margin: 100px auto;
  text-align: left;
  width: grid-width(8);

  h2 {
    margin-bottom: $line-height;
  }

  .external-notice {
    background: white;
    padding: $grid-gutter * 2;

    h3 {
      margin-bottom: $half-line;
    }

    a {
      font-size: 1em;
    }
  }
}

.password-reset-errors {
  color: $color-alert;
  font-family: inherit;
  font-size: 100%;
  margin-bottom: 2em;
  text-align: center;
}

.password-reset-requirements {
  border-left: 2px solid $color-part-2;
  color: $color-text-light;
  padding: 0.25em;
  padding-left: 0.75em;
  font-size: $font-size-small;
  line-height: $line-height-small;
  margin: 0 0 2em 0;
}
