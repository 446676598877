@import '../mixins/clearfix';

html {
  height: 100%;
  overflow-y: scroll;
}

body {
  background: $color-background-creamy !important;
  color: $color-text-dark;
  font: 12px/1.5em $sans-serif-font;
  height: 100%;
}

#wrapper {
  clear: both;
  height: auto;
  min-height: 100%;
}

#wrapper > #main {
  @extend .clearfix;

  padding: grid-units(6) 0;
}

#content {
  @extend .clearfix;

  background: $color-background-soft;
  border-radius: $radius-primary;
  box-shadow: 0 0 5px lighten($color-shadow-light, 30%);
  clear: both;
  margin-bottom: 5em;
  padding: $line-height $grid-gutter;

  @include grid-container;
}

// Footer --

body > footer {
  background: $color-background-ultra;
  color: $color-text-bright;
  height: 55px;
  line-height: $line-height;
  margin-top: 50px * -1;
  position: relative;
  width: 100%;
  z-index: 2;

  ul {
    margin: 0;
    padding: $line-height 0;

    @include grid-container;

    li {
      display: inline;
      margin-right: $grid-gutter;
    }
  }

  a {
    color: $color-text-bright;
    font-weight: bold;

    &:hover, &:focus { color: $color-text-inverted; }
  }
}

