@mixin button {
  border: none;
  border-radius: $radius-primary;
  color: $color-text;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  padding: 0.9em;
  text-align: center;

  &:hover {
    background-color: darken($color-highlight, 5%);
  }

  &[disabled],
  &.disabled {
    background: darken($color-background-light, 5%);
    border: none;
    color: lighten($color-text, 20%);
    cursor: auto;
    box-shadow: none;
    text-shadow: none;
  }

  &[disabled]:hover,
  &.disabled:hover {
    background: darken($color-background-light, 5%);
    color: lighten($color-text, 20%);
  }
}

@mixin color-override($base-color, $text-color: white) {
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient($base-color, darken($base-color, 5%));
  box-shadow: 0 0 1px darken($base-color, 30%);

  &:hover {
    color: $text-color;
    background: linear-gradient(
      darken($base-color, 5%),
      darken($base-color, 10%)
    );
  }
}

button[disabled] {
  cursor: default;
}

.button {
  @include button;
}

.circular-button {
  background: linear-gradient(
    $color-background-light,
    $color-background-medium
  );
  border: 1px solid darken($color-background-medium, 5%);
  border-radius: 10px;
  color: lighten($color-text, 5%);
  cursor: pointer;
  display: block;
  font-size: 1.3em;
  font-weight: bold;
  height: 18px;
  line-height: 13px;
  margin: 10px 0 0 12px;
  width: 18px;
  text-align: center;
  text-shadow: 0 1px -1px darken(white, 10%);
  transition: all, 0.1s;

  &:hover {
    color: darken($color-text, 5%);
    background: linear-gradient(
      darken($color-background-light, 5%),
      darken($color-background-medium, 5%)
    );
  }
}

.basic {
  @include color-override($color-background-ghost);

  border: 1px solid $color-background-medium;
  color: $color-text-light;
  padding: 7px;
  text-shadow: none;

  &:hover {
    color: $color-text-light;
  }
}

.primary {
  @include color-override($color-highlight);

  &.modal {
    position: absolute;
    right: $grid-gutter;
    bottom: $line-height;

    &.multiple {
      position: static;
      float: right;
    }
  }
}
.info {
  @include color-override(lighten($color-highlight, 5%));
}
.relaxed {
  @include color-override($color-background-dark);
}
.success {
  @include color-override($color-success);
}
.danger {
  @include color-override($color-danger);
}

// Custom Buttons -------------------------------------------------------------

.action-button {
  padding: $half-gutter;
}

.js-export-accounts {
  &:hover {
    background: none;
  }
}
