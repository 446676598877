$modal-export-max: 720px;
$sidebar-width:    300px;

.modal-host {
  position: relative;
}

.modal-outer {
  background-color: $color-overlay-open;
  display: block;
  height: 100%;
  left: 0;
  box-sizing: border-box;
  padding: 55px 0px 55px 0px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: index($elements, modal-overlay);

  &.exports {
    @extend .vertical-align;

    text-align: center;

    .modal-body {
      display: inline-block;
      vertical-align: middle;
      width: $modal-export-max;

      @media (max-width: 850px) {
        width: 500px;
      }

      &.modal-material {
        width: 400px;
      }

      &.modal-small {
        width: 350px;

        .modal-close {
          display: none
        }
      }
    }
  }

  &.closed {
    background-color: $color-overlay-closed;
    height: 0;
    padding: 0;
    transition: background-color 0.3s, height 0s;

    .modal-body {
      transform: scale(0);
    }
  }

  &:focus {
    outline: none;
  }
}

.modal-body {
  background-color: $color-white;
  border-radius: $radius-primary;
  box-shadow: 0 0 grid-units(3) $color-overlay-open;
  margin: 0 auto;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
  text-align: left;
  transition: transform, 0.3s, ease-in-out, 0s;
  width: 100%;
  z-index: index($elements, modal-body);
}

.modal-close {
  color: $color-charcoal;
  cursor: pointer;
  display: block;
  position: absolute;
  right: grid-units(2);
  top: grid-units(2);
  z-index: index($elements, modal-close);

  &::after {
    @include iconfont;

    content: $ds-icon-close;
    display: block;
    font-size: $font-size-icon;
    height: $font-size-icon;
    width: $font-size-icon;
  }
}

.modal-title {
  background-color: $color-white;
  border-bottom: 1px solid $color-border;
  border-radius: $radius-primary $radius-primary 0 0;
  color: $color-charcoal;
  font-size: $font-size-large;
  font-weight: 400;
  line-height: grid-units(3);
  margin: 0;
  padding: grid-units(2);
}

.modal-launch {
  aside {
    background: $color-background-soft;
    border-bottom: 1px solid $color-background-medium;
    color: $color-ui-gray-4;
    font-size: $font-size-normal;
    line-height: 1.6;
    padding: $grid-gutter;

    p {
      line-height: inherit;
      margin-bottom: $half-line;
    }

    ol {
      margin-left: $grid-gutter;
    }

    li {
      list-style-type: disc;
      list-style-position: outside;

      &+li {
        margin-top: $half-line;
      }
    }
  }

  .button {
    float: right;
  }

  .processing::before {
    @include iconfont;

    content: $ds-icon-loader;
    display: block;
    float: left;
    margin-right: 5px;
    position: relative;

    animation: rotation 1.5s linear infinite;
  }

  &.with-credit-card-form {
    max-height: 530px;
  }

  .launch-error-message {
    color: $color-red;
    text-align: center;
  }

  .launch-modal-menu {
    padding: 0 grid-units(2);
    overflow: hidden;
  }

  .launch-modal-total {
    font-size: 24px;
    float: left;
    line-height: grid-units(5);
  }
}

.modal-confirm {
  text-align: center;
  margin: 20px;

  h2 {
    line-height: 32px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    line-height: 19px;
  }

  .button {
    width: 70%;
    font-weight: 400;
    font-size: 14px;
    float: none;
    padding-right: 8px;
    padding-left: 8px;

    &.yes {
      width: 40%;
      margin-right: 20px;
    }

    &.no {
      width: 40%;
      margin-right: 0px;
    }
  }

  a.cancel {
    font-size: 14px;
    display: block;
    margin-top: 20px;
  }
}

.modal-arrow {
  cursor: pointer;
  color: transparent;
  display: block;
  height: 50px;
  left: 32px;
  margin-top: -25px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  width: 50px;

  &::before {
    background-color: $color-shadow;
    border-radius: 4px;
    color: darken($color-text-inverted, 15%);
    content: $ds-icon-arrow-left;
    font-family: $iconfont;
    font-size: 22px;
    left: 0;
    padding: 16px 8px;
    position: absolute;
    top:0;
  }

  &:hover::before {
    color: $color-text-inverted;
  }

  &.next {
    left: auto;
    right: 32px;

    &::before {
      content: $ds-icon-arrow-right;
    }
  }
}
