@import '../alpha/variables';
@import '../alpha/grid';

$blitz-max-width: 400px;

.blitz {
  border-radius: $radius-primary;
  bottom: grid-units(7);
  color: $color-white;
  max-width: $blitz-max-width;
  position: fixed;
  right: $grid-unit;
  transition: right 0.3s ease-in-out;
  z-index: 10000;

  &.blitz-hide {
    right: -(grid-units(50));
  }

  &.notice {
    background-color: $color-dscout;
  }

  &.alert {
    background-color: $color-alert;
  }

  &.error {
    background-color: $color-red;
  }

  a {
    color: $color-white;
    font-weight: inherit;
    text-decoration: underline;
  }
}

.blitz-inner-wrapper {
  max-height: ($line-height-normal * 3) + grid-units(2);
  min-height: $line-height-normal + grid-units(2);
  overflow: hidden;
  position: relative;
  width: 100%;
}

.blitz-spinner {
  @extend .vertical-align;

  display: inline-block;
  height: 100%;
  left: 0;
  padding-left: 10px;
  position: absolute;
  top: 0;
  width: grid-units(6);

  span:first-child {
    opacity: 1;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top-color: $color-white;
    border-radius: 100%;
    display: inline-block;
    height: 30px;
    vertical-align: middle;
    width: 30px;

    animation: rotation 1.5s linear infinite;
  }

  + .blitz-body {
    margin-left: grid-units(2);
    max-width: 312px;
  }

  &.blitz-hide {
    opacity: 0;
  }
}

.blitz-body {
  $close-width: grid-units(6);

  display: inline-block;
  line-height: $line-height-small;
  margin-right: $close-width;
  max-width: $blitz-max-width - $close-width;
  padding: $grid-unit grid-units(2);

  > .blitz-message,
  > a {
    line-height: inherit;
  }

  > .blitz-message {
    font-size: $font-size-small;
    font-weight: 300;
    margin: 0;

    a {
      color: $color-white !important;
      font-weight: 400;
      text-decoration: underline !important;
      border: 0 !important;
    }
  }

  > a {
    color: $color-white;
    display: block;
    font-weight: 400;
    text-decoration: underline;
  }
}

.blitz-close {
  @extend .vertical-align;

  background-color: inherit;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  color: inherit;
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: grid-units(6);

  &::after {
    @include iconfont;

    content: $ds-icon-close;
    cursor: pointer;
    font-size: $font-size-icon;
  }
}
