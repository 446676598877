@mixin clearfix {
  display: block;

  &::after {
    clear: both;
    content: ' ';
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
    width: 0;
  }
}

.clearfix {
  @include clearfix;
}

