@import '../mixins/z-index';

.content {
  @include grid-base;

  min-height: $line-height * 20;
  width: grid-width(10);

  hgroup {
    margin-bottom: $line-height;

    &.simple { border: 0; padding-bottom: $half-line; }

    h2 {
      font-size: 1.25em;
      margin-bottom: $line-height * 0.25;
    }

    h6 { color: $color-text-subdued; }
  }

  &.expanded {
    margin-right: 0;
    width: grid-width(13);
  }
}

.content-bar {
  background: linear-gradient($color-background-medium, darken($color-background-medium, 20%));
  height: 55px;
  margin-bottom: 20px;
  position: relative;
  transition: margin-bottom 0.25s ease-in-out;
  z-index: 100;

  &.free-floating {
    border-radius: $radius-primary;
    box-shadow: 0 1px 3px lighten($color-shadow-light, 15%);
  }

  &.attached-drawer { margin-bottom: 100px; }

  h3 {
    float: left;
    font-weight: 600;
    line-height: $line-height;
    margin: 0;
    padding: 15px 0 0 $grid-gutter;
    width: 70%;

    em {
      color: darken($color-text-subdued, 20%);
      vertical-align: top;
    }

    span { vertical-align: top; }
  }

  aside {
    padding: $half-gutter $half-gutter 0 0;
    position: absolute;
    right: 0;

    a {
      display: inline-block;
      margin-left: $half-gutter;
      padding: 10px;
    }
  }
}

.mini-bar {
  background: linear-gradient($color-background-soft, $color-background-light);
  border-bottom: 1px solid $color-background-medium;
  border-radius: $radius-primary $radius-primary 0 0;
  color: $color-text-dark;
  height: $line-height * 2;
  margin-bottom: $half-line;
  position: relative;
  z-index: index($elements, top-toolbar);

  button.menu-button { padding: 12.5px; }

  h3 {
    float: left;
    font-size: 14px;
    font-weight: 600;
    line-height: $line-height;
    margin: 0;
    padding: 0;
    text-transform: none;
    width: 70%;

    em {
      color: darken($color-text-subdued, 20%);
      vertical-align: baseline;
    }

    span { vertical-align: baseline; }
  }

  aside {
    position: absolute;
    right: 0;
  }
}
