@import '../alpha/variables';

.dropdown-menu {
  $menu-width: 250px;

  display: inline;

  button {
    background: $color-highlight;
    border-left: solid 1px $color-background-light;
    color: $color-text-inverted;
    padding: $half-line + 2px;
    padding-top: $half-line + 3px;

    &.disabled { background-color: darken($color-background-soft, 20%); }

    &::after {
      @include iconfont;
      content: $ds-icon-menu-arrow-down;
      margin-left: 5px;
    }
  }

  ul.menu {
    background: $color-background-paper;
    border: 1px solid $color-background-medium;
    box-shadow: 0 2px 3px $color-shadow-subdued;
    display: none;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: $menu-width;

    &.active { display: block; }

    li {
      border-bottom: 1px solid $color-background-medium;
      cursor: pointer;
      display: block;
      line-height: $line-height * 2;
      padding-left: $half-gutter;

      &:hover  { background-color: $color-background-ghost; }
      &:active { background-color: lighten($color-highlight, 50%); }

      &:last-child { border-bottom: none; }
    }
  }
}
