// Global ---------------------------------------------------------------------

html {
  height: 100%;
  overflow-y: scroll;
}

body {
  color: $color-text-dark;
  font-family: $sans-serif-font;
  margin: 0;
  padding: 0;
  text-align: center;
  height: 100%;

  // Not the same as .wrapper, but matches global/global definition of #wrapper
}

// Base -----------------------------------------------------------------------

.wrapper {
  overflow: hidden;
  text-align: left;

  @include grid-container;
}

.blitz {
  border-radius: $radius-primary;
  margin: 0 auto $line-height;
  width: grid-width(8) + $grid-gutter;
}

p {
  color: #404040;
  font-size: 14px;
  line-height: 1.4em;
  margin: 0;
  padding: 0 0 ($line-height * 0.5) 0;
  &.intro-text { font-size: 18px; }
}

h1 {
  font-family: $sans-serif-font;
  font-size: 36px;
  font-weight: lighter;
  padding-bottom: $line-height * 0.5;
}

h2 {
  color: $color-text-dark;
  font-family: $sans-serif-font;
  font-size: 21px;
  font-weight: normal;
  line-height: 1.2em;
  margin: 0;
  padding: 0 0 6px 0;
}

h3 {
  color: #666;
  font-family: $sans-serif-font;
  font-size: 14px;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  text-shadow: 1px 1px 1px #fff;
}

hr {
  &.padding {
    border: none;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  &.embossed {
    border-bottom: 1px solid $color-background-paper;
    border-top: 1px solid $color-background-medium;
  }
}
