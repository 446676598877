ol {
  list-style-type: decimal;

  li { line-height: $line-height; }
}

li {
  ul, ol { margin: 0; }
}

dl { margin: 0 0 1.5em 0 }
dt { font-weight: bold; }
dd { margin-left: 1.5em; }

@mixin simple-list {
  li { padding-bottom: 0.5em; }
}

@mixin divided-list {
  border-bottom: 2px solid $color-background-dark;
  border-top: 2px solid $color-background-dark;
  margin-bottom: 1em;

  li + li {
    border-top: 1px solid $color-background-light;
  }
}

@mixin self-rounding-list {
  &:first-child {
    border-radius: $radius-primary $radius-primary 0 0;
  }

  &:last-child {
    border-radius: 0 0 $radius-primary $radius-primary;
  }

  &:only-child {
    border-radius: $radius-primary;
  }
}

.simple-list        { @include simple-list; }
.divided-list       { @include divided-list; }
.self-rounding-list { @include self-rounding-list; }
