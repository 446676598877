// Hide

.hide, .hidden {
  display: none;
}

.last {
  margin-bottom: 0;
  padding-bottom: 0;
}

// Float group for grouping items

.float-group {
  float: left;
  overflow: hidden;
}

.well {
  background: $color-background-soft;
  border: 1px solid $color-background-medium;
  border-radius: $radius-tiny;
  padding: $half-gutter;
}

.vertical-align {
  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    margin-right: -0.25em;
    vertical-align: middle;
    visibility: hidden;
  }
}

.margin-b--base {
  margin-bottom: 32px;
}

.margin-b--m {
  margin-bottom: 16px;
}

.margin-t--base {
  margin-top: 32px;
}

.margin-t--m {
  margin-top: 16px;
}

.text-align--c {
  text-align: center;
}
