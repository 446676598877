@import '../mixins/clearfix';

.sidebar {
  @extend .clearfix;

  @include grid-base;

  margin-left: 0;
  min-height: 400px;
  width: grid-width(3);

  h3 {
    background: linear-gradient($color-background-soft, $color-background-light);
    border-radius: $radius-primary $radius-primary 0 0;
    border: 1px solid $color-background-medium;
    box-sizing: border-box;
    color: $color-text-dark;
    font-size: 14px;
    font-weight: normal;
    height: $line-height * 2;
    line-height: $line-height;
    margin: 0;
    padding: $half-line;
    text-transform: none;
  }

  // Used as headers within a listing
  h4 {
    border-top: 1px solid darken($color-background-light, 5%);
    color: lighten($color-text-light, 3%);
    font-size: 14px;
    margin: $half-line 0 4px 0;
    padding: $half-line 0 3px $half-line;
  }

  // Used as a header outside of a listing
  h5 {
    color: lighten($color-text-light, 3%);
    font-size: 14px;
    margin-bottom: $half-line;
  }

  ol {
    background: $color-background-paper;
    border: 1px solid $color-background-medium;
    border-radius: 0 0 $radius-primary $radius-primary;
    counter-reset: customCounter;
    border-top: none;
    margin-top: 0;
    padding: 5px 0;

    li {
      list-style: decimal inside !important;

      // Until we figure out why the ol won't display the list-style
      // properly, we have to use the :before psuedo-selector to fake it.

      &:before {
        color: $color-text-subdued;
        content: counter(customCounter) ".";
        counter-increment: customCounter;
        display: inline;
        float: left;
        margin-right: $half-gutter;
      }
    }
  }

  ul {
    background: $color-background-paper;
    border: 1px solid $color-background-medium;
    border-top: none;
    border-radius: 0 0 $radius-primary $radius-primary;
    margin-top: 0;
    margin-bottom: $line-height;
    padding: 5px 0;
  }

  li {
    display: block;
    line-height: $line-height * 1.5;
    padding: 0 0 0 $half-line;

    &:first-child { margin-top: $half-line * 0.5; }
  }

  a {
    color: lighten($color-text-light, 30%);
    display: block;
    font-size: 1.16em;
    font-weight: bold;
    line-height: inherit;

    &:hover, &:active, &.active { color: $color-highlight; }
  }
}
