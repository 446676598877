// Colors ---------------------------------------------------------------------

$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$color-alert: #e51546;
$color-red: rgb(231, 76, 60);
$color-green: rgb(46, 204, 113);
$color-charcoal: rgb(64, 64, 64); // #404040
$color-dscout: #0a73eb;

$color--faint: #fbfbfb;
$color--faint-s: #f4f4f4;

$color-ui-gray-1: rgb(242, 242, 242); // light
$color-ui-gray-2: rgb(204, 204, 204); // soft #ccc
$color-ui-gray-3: rgb(153, 153, 153); // medium #999
$color-ui-gray-4: rgb(102, 102, 102); // dark #666
$color-ui-gray-5: rgb(249, 249, 249); // bright
$color-ui-gray-6: rgb(232, 232, 232); // tags
$color-ui-gray-7: rgb(230, 230, 230); // summary sidebar right

$color-ui-highlight: #0a73eb;

$color-overlay-closed: rgba(0, 0, 0, 0);
$color-overlay-open: rgba(0, 0, 0, 0.5);
$color-toolbar: rgba(255, 255, 255, 0.98);

// -- For part pills --
$color-part-0: #2bae92;
$color-part-1: #a5d570;
$color-part-2: #ffd566;
$color-part-3: #fead04;
$color-part-4: #e76161;
$color-part-5: #f2659a;
$color-part-6: #aa81d2;
$color-part-7: #00acf1;
$color-part-8: #8edde8;
$color-part-9: #86c4c2;

$part-colors: (
  $color-part-0,
  $color-part-1,
  $color-part-2,
  $color-part-3,
  $color-part-4,
  $color-part-5,
  $color-part-6,
  $color-part-7,
  $color-part-8,
  $color-part-9
);

// -- OLD --
$color-background-paper: #ffffff;
$color-background-soft: #f9f9f9;
$color-background-ghost: #f5f7f8;
$color-background-creamy: #eaeaea;
$color-background-light: #eeeeee;
$color-background-medium: #cfcfcf;
$color-background-dark: #666666;
$color-background-unlit: #333333;
$color-background-super: #2a2a2a;
$color-background-ultra: #1a1a1a;
$color-background-smokey: #b8c3c9;

// Borders & Shadows ----------------------------------------------------------

$color-shadow: rgba(0, 0, 0, 0.15); // 15% transparent black
$color-border: rgba(0, 0, 0, 0.1); // 10% transparent black
$color-border-light: rgba(0, 0, 0, 0.07); // 7% transparent black
$color-chart-shadow: rgba(0, 0, 0, 0.05); // 5% transparent black
$color-border-inverted: rgba(255, 255, 255, 0.15); // 15% transparent white

// -- OLDER --

$color-shadow-dark: #1a1a1a;
$color-shadow-medium: #2a2a2a;
$color-shadow-light: #6a6a6a;
$color-shadow-subdued: #dfdfdf;

// UI Specific ----------------------------------------------------------------

$color-footer-btn: rgba(153, 153, 153, 0.15);
$color-hover-bg: rgba(0, 0, 0, 0.02);
$color-progress-bg: rgba(0, 0, 0, 0.15);

// Emphasis -------------------------------------------------------------------

$color-highlight: $color-dscout; // Primary blue
$color-emphasis: #dfa600; // Pale orange
$color-editable: #fff48c; // Pale yellow
$color-expiring: #f9edbe; // Creamy yellow
$color-caution: #f7941d; // Burnt orange
$color-action: #1bbf9c; // Sea green
$color-attention: #ffbe35; // Gold
$color-marker: #fff48c; // Bright green
$color-inactive: desaturate($color-highlight, 80%);
$color-active: desaturate($color-highlight, 10%);
$color-selected: lighten($color-highlight, 50%);

$color-success: #27ae60;
$color-danger: #e74c3c;
$color-notice: #00a2e0;
$color-alert: #e51546;
$color-error: #ff6666;
$color-error-light: #ffeeee;

$color-rating-awesome: $color-dscout;
$color-rating-good: #7dd5ea;
$color-rating-so-so: #8692a5;
$color-rating-way-off: #454545;
$color-rating-no-rating: #cfcfcf;

// Text -----------------------------------------------------------------------

$color-text: #444444;
$color-text-ultra: #111111;
$color-text-dark: #333333;
$color-text-light: #52595d;
$color-text-medium: #777777;
$color-text-subdued: #999999;
$color-text-bright: #cccccc;
$color-text-inverted: #ffffff;

$font-size-icon: 22px;
$font-size-large: 20px;
$font-size-medium: 16px;
$font-size-normal: 14px;
$font-size-small: 12px;
$font-size-tiny: 10px;
$font-weight-normal: 400;

$line-height-normal: 24px;
$line-height-large: 32px;
$line-height-small: 20px;
$line-height-very-small: 16px;

$iconfont: 'dscout-ui-icons';
$serif-font: Georgia, Times New Roman, Times, serif;
$sans-serif-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

// Radii ----------------------------------------------------------------------

$radius-circle: 50%;
$radius-primary: 4px;
$radius-secondary: 10px;
$radius-tiny: 3px;

// Email Communication --------------------------------------------------------

$email-color-bg: #f4f4f4;
$email-text-color: #222222;

// Icons ----------------------------------------------------------------------

$ds-icon-add: '\e601';
$ds-icon-add-circle: '\e625';
$ds-icon-add-fill: '\e600';
$ds-icon-add-user: '\e903';
$ds-icon-alert: '\e643';
$ds-icon-alert-fill: '\e642';
$ds-icon-alert-fill2: '\e602';
$ds-icon-alert2: '\e603';
$ds-icon-application: '\e692';
$ds-icon-application-fill: '\e691';
$ds-icon-archive: '\e671';
$ds-icon-archive-fill: '\e670';
$ds-icon-arrow-left: '\e62e';
$ds-icon-arrow-right: '\e62f';
$ds-icon-back: '\e69c';
$ds-icon-back-fill: '\e69b';
$ds-icon-badge: '\e65f';
$ds-icon-badge-fill: '\e65e';
$ds-icon-bookmark: '\e605';
$ds-icon-bookmark-fill: '\e604';
$ds-icon-bookmark-fill2: '\e677';
$ds-icon-bookmark2: '\e678';
$ds-icon-calendar: '\e69a';
$ds-icon-calendar-fill: '\e699';
$ds-icon-camera: '\e694';
$ds-icon-camera-fill: '\e693';
$ds-icon-camera-flash-auto: '\e69d';
$ds-icon-camera-flash-off: '\e69e';
$ds-icon-camera-flash-on: '\e69f';
$ds-icon-camera-flip: '\e6a0';
$ds-icon-camera-frame: '\e6a3';
$ds-icon-camera-frame-grid: '\e6a1';
$ds-icon-camera-frame-selfie: '\e6a2';
$ds-icon-cash: '\e661';
$ds-icon-cash-fill: '\e660';
$ds-icon-chart: '\e612';
$ds-icon-chart-fill: '\e6a4';
$ds-icon-chat: '\e63f';
$ds-icon-chat-fill: '\e63e';
$ds-icon-check-circle: '\e645';
$ds-icon-check-circle-fill: '\e644';
$ds-icon-checkmark: '\e62d';
$ds-icon-circle: '\e63b';
$ds-icon-circle-fill: '\e63a';
$ds-icon-circle-fill-alt: '\e6a5';
$ds-icon-clock: '\e684';
$ds-icon-clock-fill: '\e683';
$ds-icon-close: '\e607';
$ds-icon-close-circle: '\e626';
$ds-icon-close-fill: '\e606';
$ds-icon-cloud-download: '\e646';
$ds-icon-cloud-upload: '\e647';
$ds-icon-compose: '\e900';
$ds-icon-copy: '\e90d';
$ds-icon-download: '\e614';
$ds-icon-download-fill: '\e613';
$ds-icon-dscout-icon: '\e630';
$ds-icon-facebook: '\e654';
$ds-icon-fader: '\e676';
$ds-icon-fader-fill: '\e688';
$ds-icon-filter: '\e609';
$ds-icon-filter-fill: '\e608';
$ds-icon-flag: '\e64b';
$ds-icon-flag-fill: '\e64a';
$ds-icon-forward: '\e68a';
$ds-icon-forward-fill: '\e689';
$ds-icon-gift: '\e665';
$ds-icon-gift-card: '\e663';
$ds-icon-gift-card-fill: '\e662';
$ds-icon-gift-fill: '\e664';
$ds-icon-grad-cap: '\e690';
$ds-icon-grad-cap-fill: '\e68f';
$ds-icon-grid: '\e615';
$ds-icon-help: '\e638';
$ds-icon-help-fill: '\e637';
$ds-icon-info: '\e60b';
$ds-icon-info-fill: '\e60a';
$ds-icon-invite-count: '\e902';
$ds-icon-invite-count-fill: '\e901';
$ds-icon-like: '\e641';
$ds-icon-like-fill: '\e640';
$ds-icon-linkedin: '\e655';
$ds-icon-list: '\e616';
$ds-icon-list-general: '\e657';
$ds-icon-list-options: '\e617';
$ds-icon-loader: '\e687';
$ds-icon-location: '\e659';
$ds-icon-location-fill: '\e658';
$ds-icon-magic: '\e639';
$ds-icon-map: '\e653';
$ds-icon-map-fill: '\e650';
$ds-icon-map-pin: '\e652';
$ds-icon-map-pin-fill: '\e651';
$ds-icon-media-player: '\e67c';
$ds-icon-media-player-fill: '\e67b';
$ds-icon-menu-arrow-down: '\e629';
$ds-icon-menu-arrow-left: '\e679';
$ds-icon-menu-arrow-right: '\e67a';
$ds-icon-menu-arrow-up: '\e62a';
$ds-icon-message: '\e619';
$ds-icon-message-fill: '\e618';
$ds-icon-message-group: '\e65b';
$ds-icon-message-group-fill: '\e65a';
$ds-icon-missions: '\e669';
$ds-icon-missions-fill: '\e668';
$ds-icon-move-vertical: '\e90a';
$ds-icon-nav-arrow-left: '\e61a';
$ds-icon-nav-arrow-right: '\e61b';
$ds-icon-notes: '\e60d';
$ds-icon-notes-fill: '\e60c';
$ds-icon-number-question: '\e674';
$ds-icon-opportunities: '\e66b';
$ds-icon-opportunities-fill: '\e66a';
$ds-icon-overflow: '\e632';
$ds-icon-overflow-fill: '\e631';
$ds-icon-paragraph: '\e61c';
$ds-icon-payment: '\e68e';
$ds-icon-payment-fill: '\e68d';
$ds-icon-pencil: '\e686';
$ds-icon-pencil-fill: '\e685';
$ds-icon-permissions: '\e673';
$ds-icon-permissions-fill: '\e672';
$ds-icon-phone: '\e907';
$ds-icon-phone-fill: '\e906';
$ds-icon-photo: '\e61e';
$ds-icon-photo-fill: '\e61d';
$ds-icon-photo-stack: '\e66d';
$ds-icon-photo-stack-fill: '\e66c';
$ds-icon-playback-ff: '\e681';
$ds-icon-playback-pause: '\e67f';
$ds-icon-playback-play: '\e680';
$ds-icon-playback-rw: '\e67e';
$ds-icon-playback-skip-next: '\e682';
$ds-icon-playback-skip-prev: '\e67d';
$ds-icon-printer: '\e698';
$ds-icon-printer-fill: '\e697';
$ds-icon-pro-tip: '\e905';
$ds-icon-pro-tip-fill: '\e904';
$ds-icon-screen-recording: '\e962';
$ds-icon-remove: '\e636';
$ds-icon-remove-fill: '\e635';
$ds-icon-reply: '\e68c';
$ds-icon-reply-fill: '\e68b';
$ds-icon-rotate: '\e61f';
$ds-icon-scale: '\e620';
$ds-icon-scale-fill: '\e675';
$ds-icon-search: '\e60f';
$ds-icon-search-fill: '\e60e';
$ds-icon-settings: '\e622';
$ds-icon-settings-fill: '\e621';
$ds-icon-share: '\e65d';
$ds-icon-share-fill: '\e65c';
$ds-icon-social: '\e649';
$ds-icon-social-fill: '\e648';
$ds-icon-stack: '\e696';
$ds-icon-stack-fill: '\e695';
$ds-icon-star: '\e63d';
$ds-icon-star-fill: '\e63c';
$ds-icon-switch-off: '\e62b';
$ds-icon-switch-on: '\e62c';
$ds-icon-tag: '\e611';
$ds-icon-tag-fill: '\e610';
$ds-icon-tag-plus: '\e64d';
$ds-icon-tag-plus-fill: '\e64c';
$ds-icon-target: '\e909';
$ds-icon-target-fill: '\e908';
$ds-icon-trash: '\e628';
$ds-icon-trash-fill: '\e627';
$ds-icon-twitter: '\e656';
$ds-icon-unlike: '\e667';
$ds-icon-unlike-fill: '\e666';
$ds-icon-user: '\e634';
$ds-icon-user-female: '\e66f';
$ds-icon-user-female-fill: '\e66e';
$ds-icon-user-fill: '\e633';
$ds-icon-video: '\e624';
$ds-icon-video-fill: '\e623';
$ds-icon-wrench: '\e64f';
$ds-icon-wrench-fill: '\e64e';

// -- OLD --

$icon-avatar: '\1f464';
$icon-contact-page: '\e03b';
$icon-facebook: '\e600';
$icon-letter: '\4d';
$icon-page: '\e03a';
$icon-twitter: '\e601';
