$line-height:    20px; // Baseline grid
$grid-column:    50px; // Column size
$grid-gutter:    20px; // Gutter size
$grid-unit:      8px;  // NEW gutter size
$half-gutter:    10px; // Smaller gutter
$half-line:      10px; // Half baseline grid
$quarter-gutter: 5px;  // Smallest gutter
$quarter-line:   5px;  // Smallest baseline grid

@function grid-units($n) {
  @return $n * $grid-unit;
}

@function grid-width($n) {
  @return $n * $grid-column + ($n - 1) * $grid-gutter;
}

$col-1:  grid-width(1);
$col-2:  grid-width(2);
$col-3:  grid-width(3);
$col-4:  grid-width(4);
$col-5:  grid-width(5);
$col-6:  grid-width(6);
$col-7:  grid-width(7);
$col-8:  grid-width(8);
$col-9:  grid-width(9);
$col-10: grid-width(10);
$col-11: grid-width(11);
$col-12: grid-width(12);
$col-13: grid-width(13);
$col-14: grid-width(14);
$col-15: grid-width(15);
$col-16: grid-width(16);

@mixin grid-container {
  margin-left: auto;
  margin-right: auto;
  width: grid-width(16);
}

@mixin grid-base {
  display:inline;
  float: left;
  position: relative;
  margin-left: $half-gutter;
  margin-right: $half-gutter;
  box-sizing: border-box;
}

.container { @include grid-container; }

@mixin grid-1  { @include grid-base; width: $col-1; }
@mixin grid-2  { @include grid-base; width: $col-2; }
@mixin grid-3  { @include grid-base; width: $col-3; }
@mixin grid-4  { @include grid-base; width: $col-4; }
@mixin grid-5  { @include grid-base; width: $col-5; }
@mixin grid-6  { @include grid-base; width: $col-6; }
@mixin grid-7  { @include grid-base; width: $col-7; }
@mixin grid-8  { @include grid-base; width: $col-8; }
@mixin grid-9  { @include grid-base; width: $col-9; }
@mixin grid-10 { @include grid-base; width: $col-10; }
@mixin grid-11 { @include grid-base; width: $col-11; }
@mixin grid-12 { @include grid-base; width: $col-12; }
@mixin grid-13 { @include grid-base; width: $col-13; }
@mixin grid-14 { @include grid-base; width: $col-14; }
@mixin grid-15 { @include grid-base; width: $col-15; }
@mixin grid-16 { @include grid-base; width: $col-16; }
