@import '../mixins/z-index';

$header-height: grid-units(6);

@mixin header-text {
  color: $color-black;
  display: inline-block;
  font-size: $font-size-normal;
  font-weight: 600;
  text-overflow: ellipsis;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
}

.metanav-header {
  background: $color-toolbar;
  border-bottom: 1px solid $color-border;
  font-size: $font-size-normal;
  height: $header-height;
  line-height: $header-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; // index($elements, top-toolbar);
  display: flex;
  flex-direction: row;
}

.header-logo {
  border: 0;
  padding: 14px 0 0 16px;
  flex: 0 0 auto;
  margin-right: $grid-unit;
  transition: color 0.3s;

  @include iconfont;

  &:after {
    content: $ds-icon-dscout-icon;
    display: block;
    font-size: 26px;
  }

  &:hover {
    border: 0;
    color: darken($color-dscout, 10%);
  }
}

.header-breadcrumb {
  @include header-text;

  flex: 0 0 auto;
  margin: 0;
  max-width: grid-units(28.5);
  overflow: hidden;
  line-height: 32px;
  border-right: 1px solid $color-border;
  height: 32px;
  padding: 1px grid-units(2) 0 0;
  align-self: center;
}

.header-menu {
  flex: 0 0 auto;
  display: flex;
  padding-left: grid-units(2);
  height: 48px;
}

.header-menu-link {
  flex: 0 0 auto;
  color: $color-ui-gray-4;
  padding-top: 18px;
  margin-right: grid-units(2);

  &:hover {
    opacity: 0.5;
  }

  &.active {
    border-bottom: 2px solid $color-dscout;
    color: $color-black;
  }
}

.header-menu-item {
  position: relative;

  > span {
    color: $color-ui-gray-3;
    cursor: pointer;
    font-weight: 600;
    display: inline;
    vertical-align: middle;
  }
}

.header-menu-nav {
  background: $color-white;
  border-radius: $radius-primary;
  box-shadow: 0 1px 3px $color-shadow;
  display: block;
  max-height: grid-units(62);
  overflow-y: scroll;
  padding: grid-units(2);
  position: absolute;
  top: grid-units(5);
  width: grid-units(45);

  .group-title-wrapper {
    display: block;
    border-bottom: 1px solid $color-ui-gray-2;
    line-height: $line-height-normal;
    margin-bottom: grid-units(2);
    overflow: hidden;
    padding: grid-units(1) 0;
    width: 100%;
  }

  .group-title {
    color: $color-ui-gray-2;
    float: left;
    font-size: $font-size-small;
    line-height: inherit;
    margin: 0;
    text-transform: uppercase;
  }

  .group-action {
    color: $color-dscout;
    cursor: pointer;
    float: right;
    font-size: $font-size-normal;
    line-height: inherit;
    margin: 0;
  }

  .group-body {
    cursor: pointer;
    line-height: $line-height-normal;
    margin-bottom: grid-units(1);
  }

  .link-title {
    color: $color-ui-gray-4;
    display: block;
    font-size: $font-size-normal;
    font-weight: 600;
    line-height: grid-units(2);
  }

  .link-meta {
    color: $color-ui-gray-2;
    font-size: $font-size-small;
    line-height: $line-height-normal;
    margin-right: grid-units(1);
  }
}

.header-researcher-nav {
  margin: 0 grid-units(2);
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    cursor: pointer;
  }

  &::after {
    content: $ds-icon-menu-arrow-down;
    display: inline-block;
    margin-left: 5px;

    @include iconfont;
  }

  &.open::after {
    content: $ds-icon-menu-arrow-up;
  }
}

.header-researcher-avatar {
  border-radius: $radius-circle;
  cursor: pointer;
  display: inline;
  height: $header-height * 0.5;
  vertical-align: middle;
  width: $header-height * 0.5;
}

.header-researcher-menu {
  background: $color-white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: $radius-primary;
  min-width: 184px;
  position: absolute;
  top: grid-units(5);
  right: 0;
  z-index: index($elements, nav-menu);

  > a {
    color: $color-ui-gray-4;
    display: block;
    padding: $grid-unit;
    line-height: grid-units(3);
    text-transform: capitalize;

    &:last-of-type, &:nth-child(3) {
      border-top: 1px solid $color-border;
    }

    &:hover {
      background: $color-hover-bg;
    }

    &.dangerous {
      color: $color-red;
    }
  }
}
