@import '../alpha/variables';

.toolbar, .monobar {
  background: linear-gradient($color-background-paper, #efeff0);
  border-radius: $radius-primary $radius-primary 0 0;
  min-height: 40px;
}

.monobar {
  color: $color-ui-gray-4;
  font-size: $font-size-normal;
  line-height: $line-height * 2;
  margin-bottom: 0;
  padding: 0 0 0 grid-units(2);
}

.monobar-appendix {
  font-weight: normal;
  margin-right: grid-units(2);
  position: absolute;
  right: 0;
  text-align: right;
}
