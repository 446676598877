// Reset ----------------------------------------------------------------------

html, body { margin: 0; padding: 0; }
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td { margin: 0; padding: 0; border: 0; font-weight: normal; font-style: normal; font-size: 100%; line-height: 1; font-family: inherit; }
table { border-collapse: collapse; border-spacing: 0; }
ol, ul { list-style: none; }
q:before, q:after, blockquote:before, blockquote:after { content: ""; }

// Normalize.css --------------------------------------------------------------

html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// Focus states

a {
  &:hover, &:active { outline: 0; }
}

// Display in IE6-9 and FF3

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

// Prevents modern browsers from displaying 'audio' without controls

audio:not([controls]) {
  display: none;
}

// Prevents sub and sup affecting line-height in all browsers

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup { top: -0.5em; }
sub { bottom: -0.25em; }

// Img border in a's and image quality

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

// Forms ----------------------------------------------------------------------

// Font size in all browsers, margin changes, misc consistency

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button, input {
  line-height: normal; // FF3/4 have !important on line-height in UA stylesheet
  *overflow: visible; // Inner spacing ie IE6/7
}

button::-moz-focus-inner,
input::-moz-focus-inner { // Inner padding and border oddities in FF3/4
  border: 0;
  padding: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; // Style clicable inputs in iOS
}

input[type="search"] { // Appearance in Safari/Chrome
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; // Inner-padding issues in Chrome OSX, Safari 5
}

textarea {
  overflow: auto; // Remove vertical scrollbar in IE6-9
  vertical-align: top; // Readability and alignment cross-browser
}
