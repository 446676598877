@import '../mixins/clearfix';

.sign-up-page {
  .signup-page {
    margin-bottom: $line-height;
  }

  .main-content-col {
    float: left;
    margin-right: $grid-gutter;
    width: 73%;
  }

  .sidebar {
    float: right;
    width: 25%;
  }

  .well {
    border: 1px solid $color-background-medium;
    padding: $grid-gutter;

    border-radius: $radius-primary;
  }

  .signup-form {
    fieldset {
      margin-bottom: $line-height;
      padding: $grid-gutter;

      legend {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 6px;
        white-space: normal;
        width: 100%;

        span {
          display: block;
          width: 100%;
        }
      }

      p {
        color: $color-text-subdued;
        font-family: $sans-serif-font;
      }

      label {
        display: inline-block;
        font-size: 14px;
        width: grid-width(3);

        &.required {
          color: $color-text;
          font-weight: normal;
        }
      }

      div {
        margin-bottom: $line-height;
      }

      .credit-card-images {
        margin-left: 191px;
        margin-bottom: 30px;
      }

      input[type='checkbox'],
      input[type='submit'],
      button[type='submit'] {
        margin-left: grid-width(3);
        margin-right: $grid-gutter;
      }
    }

    .boolean {
      label {
        width: 50%;
      }
    }

    span.error {
      color: red;
      display: inline;
      font-size: 12px;
      margin-left: $half-gutter;
    }
  }

  .cancel-link {
    font-size: 14px;
    font-weight: bold;
    padding: $grid-gutter * 0.5;
    padding-left: 0;
  }

  .signup-errors {
    background: lighten($color-error, 20%);
    border: 1px solid $color-error;
    margin: ($line-height * 0.5) 0;
    padding: $line-height;

    border-radius: $radius-primary;

    h3 {
      margin-bottom: $line-height * 0.5;
    }

    li {
      font-size: 14px;
    }
  }
}

#welcome,
#terms {
  #wrapper {
    background-color: #fbfbfb;
    min-height: 100%;
  }

  .required-asterisk {
    color: #7a7a7a;
  }

  .terms p {
    font-size: 14px;
  }

  .frame {
    background: $color-background-paper;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(#404040, 0.1);
    font-size: 14px;
    margin: 15px auto;
    max-width: 480px;
    padding: 32px;
    text-align: left;

    hr {
      background: #e3e3e3;
      border: none;
      height: 1px;
      margin: 32px 0;
      padding: 0;
    }

    h1 {
      font-size: 21px;
      font-weight: bold;
      color: $color-highlight;
      line-height: 31.5px;
      margin: 0;
      padding: 0 0 8px 0;
    }

    h2 {
      margin: 0;
      line-height: 25.5px;
      padding: 0;
    }

    h2 p {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    #terms-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .signup-form {
    input[type='text'],
    input[type='email'],
    input[type='password'],
    select {
      background: #f9f9f9;
      border: 1px solid #eeeeee;
      padding: 14px 8px;

      &:focus {
        border: 1px solid $color-highlight;
        box-shadow: none;
        outline: none;
      }
    }

    input[type='checkbox'] + label {
      display: inline-block;
      margin-bottom: 0;
    }

    input[type='submit'],
    button[type='submit'] {
      appearance: none;
      background-color: $color-highlight;
      border-radius: 5px;
      border: none;
      box-sizing: border-box;
      color: $color-text-inverted;
      cursor: pointer;
      display: inline-block;
      font-family: inherit;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 16px;
      outline: 0;
      padding: ($grid-unit * 1) ($grid-unit * 2);
      text-align: center;
      width: 100%;

      &:hover {
        background-color: #116bd5;
        border-bottom: none;
        color: #ffffff;
        text-decoration: none;
      }
    }

    .field_with_errors {
      label {
        color: #ef6469;
      }

      input[type='text'],
      input[type='email'],
      input[type='password'] {
        background: rgba(#fffcfc, 0.5);
        border: 1px solid #ef6469;
        padding: 14px 8px;

        &:focus {
          border: 1px solid $color-highlight;
          box-shadow: none;
          outline: none;
        }
      }
    }

    input.disabled {
      color: #888;
      background-color: #f0f0f0;
      opacity: 0.8;
      cursor: not-allowed;
    }

    .input {
      margin-bottom: 16px;
      margin-top: 0;

      input[type='text'],
      input[type='email'],
      input[type='password'],
      select {
        width: 100%;
      }

      label {
        font-size: 12px;
        line-height: 18px;
        display: block;
        margin-bottom: 8px;
        font-weight: 600;
        padding: 0;
      }

      span.error {
        display: block;
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .job-functions-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      padding-right: 34px;
    }
  }
}

.sign-up__no-account {
  padding: 1rem 0;
  text-align: center;

  a#sign_up_link {
    border-bottom: 1px solid $color-black;
    color: $color-black;
    font-weight: bold;
  }
}
