@mixin frame-basic {
  background: $color-background-paper;
  border: 1px solid darken($color-background-paper, 20%);
  color: $color-text-light;
  overflow: visible;
  padding: $line-height $grid-gutter;
}

.frame-basic {
  @include frame-basic;
  border-radius: $radius-primary;

  &.capped      { padding-top: $line-height * 4; }
  &.mini-capped { padding: 0 }
  &.skinny      { padding-left: $grid-gutter; padding-right: $grid-gutter; }

  .frame-cap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    box-sizing: border-box;
  }
}

.message {
  @include frame-basic;

  font-size: 1.3em;
  font-weight: bold;
  line-height: $line-height * 1.25;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);

  &.alert {
    background: adjust-color($color-caution, $saturation: 20%, $lightness: 20%);
    color: adjust-color($color-caution, $saturation: 80%, $lightness: -25%);
  }
}
